// import InfiniteScroll from "infinite-scroll"
import Tabulator from 'tabulator-tables';
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["auditable_tab", "audits_list", "audits_table"]

  connect() {
    // audits_table
    if (this.hasAudits_tableTarget) {
      const content_url = this.data.get("table-load-url");
      const element_id = this.data.get("activate-element-id");
      const element = document.getElementById(element_id)
      const controller = this;
      element.addEventListener("click", function handler(event) {
        controller.createAuditTable(content_url);
        // element.removeEventListener("click", handler);
      });

    }
  }

  //
  // Table
  //
  // create audit table
  async createAuditTable(url) {
    //
    const columns = [{
      title: "content",
      field: "content",
      formatter: "html",
      headerSort: false
    }]
    //
    // const per_page = this.data.get("per-page");
    const persistenceID = this.data.get("persistence-id");
    const table_target = this.audits_tableTarget;
    //
    let table_props = {
      persistenceID: persistenceID,
      layout: "fitDataStretch",
      headerSort: false,
      headerVisible: false,
      height: "100%",
      // columns
      columns: columns,
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      // Ajax
      ajaxURL: url,
      ajaxSorting: false,
      ajaxFiltering: false,
      ajaxProgressiveLoad: "scroll"
    }
    // table props
    const default_props = super.tabulatorDefaultProps
    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }



  async loadAuditableTab() {
    const url = this.data.get("tab-content-url");
    const tab_target = this.auditable_tabTarget;

    fetch(url, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/html',
        'Accept': 'application/html'
      },
      credentials: 'same-origin'
    }).then((response) => {
      if (response.status == 200) {
        response.text().then((content) => {
          tab_target.innerHTML = content;
        })
      }
    });
  }
}