import { DirectUpload } from "@rails/activestorage"

// Cookie
export function getCookie(name) {
  const ck = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return ck ? ck[2] : null;
}

export function setCookie(name, value, days) {
  const d = new Date;
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

export function deleteCookie(name) {
  setCookie(name, '', -1);
}

// Meta
export function getMetaValue(name) {
  const element = findElement(document.head, `meta[name="${name}"]`);
  if (element) {
    return element.getAttribute("content");
  }
}

// element
export function findElement(root, selector) {
  if (typeof root == "string") {
    selector = root;
    root = document;
  }
  return root.querySelector(selector);
}

// Clear selected list row
export function clearSelectedRows(tbody) {
  // let tbody = document.getElementById(tbody_id);
  const rows = tbody.querySelector('.selected-row');
  if (rows) {
    rows.classList.remove('selected-row');
  }
}

// Selected list row
export function setSelectedRow(row) {
  // const row = document.getElementById(row_id);
  if (row) {
    row.classList.add('selected-row');
  }
}

//
export function toArray(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (Array.from) {
    return Array.from(value);
  } else {
    return [].slice.call(value);
  }
}

export function removeElement(el) {
  if (el && el.parentNode) {
    el.parentNode.removeChild(el);
  }
}

export function insertAfter(el, referenceNode) {
  return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

// remove Array element
export function removeArray(arr) {
  var what, a = arguments, L = a.length, ax;
  while (L > 1 && arr.length) {
    what = a[--L];
    while ((ax = arr.indexOf(what)) !== -1) {
      arr.splice(ax, 1);
    }
  }
  return arr;
}

export function getNextSibling(elem, selector) {
  var temp = [];
  // Get the next sibling element
  var sibling = elem.nextElementSibling;

  // If the sibling matches our selector, use it
  // If not, jump to the next sibling and continue the loop
  while (sibling) {
    if (sibling.matches(selector)) {
      temp.push(sibling);
      sibling = sibling.nextElementSibling
    } else {
      break
    }
  }
  return temp;
};