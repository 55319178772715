import Tabulator from 'tabulator-tables';
import BSN from "bootstrap.native";
import vNotify from "../vanilla-notify"
import { getMetaValue } from "helpers";
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["sidebar_table", "search",
      "sidebar_sort", "sidebar_current_sort",
      "accesses_table"
  ]

  connect() {
    if (this.hasSidebar_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createSidebarTable(content_url);
    }
  }

  //
  // Actions
  //
  openNewModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  filterSidebarTable() {
    this.setFilterToTable(false);
  }


  onSelectAccessible(event) {
    let choices = event.target.choices;
    let url = event.target.getAttribute("data-create-url");
    let user_id = choices.getValue(true);

    if (user_id) {
      this.addAccessible(url, user_id);
    }
  }

  async addAccessible(url, user_id) {
    const data = new FormData();
    data.append("user_id", user_id);

    await fetch(url, {
      method: 'post',
      body: data,
      dataType: "text/javascript",
      credentials: "include",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
    }).then((response) => {
      if (response.status == 200) {
        response.text().then((content) => {
          let data = JSON.parse(content);
          window.vNotify.success({ text: data["notification_message"],
                                   title: data["notification_title"] });

          this.accesses_tableTarget.innerHTML = data["form_content"];
        })
      } else if (response.status == 422) {
          response.text().then((content) => {
          let data = JSON.parse(content);
          window.vNotify.error({ text: data["notification_message"],
                                 title: data["notification_title"] });
        })
      }
    }).catch((error) => {
      console.log(error)
    })
  }


  onPostPutSuccess(event) {

    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });

      const rowId = data.id;
      const rowAction = data.row_action;
      const rowData = data.data;

      // update table data
      this.updateDataTable(rowId, rowAction, rowData);
    }
  }

  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.error({ text: data.notification_message, title: data.notification_title });

      const rowId = data.id;
      const rowAction = data.row_action;
      const rowData = data.data;

      // update table data
      this.updateDataTable(rowId, rowAction, rowData);
    }
  }

  onAccessLevelChange(event) {
    let ul = event.target.closest("ul");
    let tr = event.target.closest("tr");
    let table = event.target.closest("table");
    let is_default = event.target.value == "default";
    let is_main = ul.classList.contains("main");
    let checkbox_buttons = tr.querySelectorAll("input[type='checkbox']");
    let group_id = tr.getAttribute("data-group-id") ;

    if (is_default) {
      checkbox_buttons.forEach(el => {
        el.disabled = true;
        el.checked = false;
      });
    } else {
      checkbox_buttons.forEach(el => {
        el.disabled = false
      });
    }

    if (is_main) {
      let childs_div = table.querySelectorAll("tr[data-group-id='" + group_id + "'] ul.child .nav-item__radio");

      if (childs_div.length > 0){

        let childs_radio = table.querySelectorAll("tr[data-group-id='" + group_id + "'] ul.child .nav-item__radio input[type='radio']");
        let childs_checkbox = table.querySelectorAll("tr[data-group-id='" + group_id + "'] input[type='checkbox']");

        if (is_default) {
          childs_div.forEach( el => {
            el.classList.add("disabled");
          })

          childs_radio.forEach( el => {
            if (el.value == "default") {
              el.checked = true
            } else {
              el.disabled = true;
            }
          });

          childs_checkbox.forEach( el => {
            el.disabled = true;
            el.checked = false;
          })

        } else {
          childs_div.forEach( el => {
            el.classList.remove("disabled");
          });
          childs_radio.forEach( el => {
            el.disabled = false;
          });
        }
      }
    }
  }

  collapseRolesSection(event) {
    event.preventDefault();
    let target = event.currentTarget;
    if (!target.collapse) target.collapse = new BSN.Collapse(target)

    if (target.classList.contains("collapsed"))
      target.collapse.show();
    else target.collapse.hide();
  }

  sidebarSortClick(event) {
    let sort_target = event.currentTarget;
    let sort_column = sort_target.dataset.sortColumn;
    let sort_dir = sort_target.dataset.sortDir;
    let sidebar_table = this.sidebar_tableTarget.tabulator;

    if (sort_dir == "asc") sort_dir = "desc"
    else sort_dir = "asc"

    sort_target.dataset.sortDir = sort_dir

    sidebar_table.setSort(sort_column, sort_dir);


    this.sidebar_sortTargets.forEach(el => {
      el.classList.remove("active");
      el.innerHTML = el.dataset.sortColumnName;
    });
    sort_target.classList.add("active");

    let new_html = sort_target.dataset.sortColumnName + "&nbsp; <i class='fa-sort-alpha-" + (sort_dir == "asc" ? "down" : "up") + " fas sort_dir'></i>";
    this.sidebar_current_sortTarget.innerHTML = new_html;
    event.target.innerHTML = new_html;
  }


  // filters
  setFilterToTable(is_mapping) {
    let table = this.sidebar_tableTarget.tabulator;
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (filters.length) {
      table.setFilter(filters);
    }
  }


  async updateDataTable(rowId, action, data) {
    const mainTable = this.accesses_tableTarget;

    switch (action) {
      case "update":
        mainTable.innerHTML = data;
        break;
      case "delete":
        mainTable.querySelectorAll("[data-user-id='"+rowId+"']").forEach(e => e.remove());
        break;
    }
  }


  async createContent(content_url) {
    const content_target = document.getElementById("content");
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    content_target.innerHTML = content;
    super.updateSidebarStick();
  }


  async createSidebarTable() {
    const url = this.data.get("table-load-url");
    const columns = this.data.get("table-columns");
    const table_target = this.sidebar_tableTarget;

    let loadSelected = this.createContent
    let puthToTurbolinks = this.historyPush

    let table_props = {
      persistenceID: "roles_sidebar_table",
      layout: "fitDataStretch",
      headerSort: false,
      headerVisible: false,
      // columns
      columns: JSON.parse(columns),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 50,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      //
      rowFormatter: function (row) {
        //row - row component
        const data = row.getData();
        if (data.url) row.getElement().dataset.url = data.url;
        if (data.history_url) row.getElement().dataset.history_url = data.history_url;

        if (data.is_selected) {
          row.select();
          row.getElement().classList.add("row-active");
        }
      },
      rowClick: function (e, row) {
        const content_url = row.getElement().dataset.url;
        const history_url = row.getElement().dataset.history_url;
        loadSelected(content_url);
        let row_table = row.getTable();
        row_table.deselectRow();
        row.select();
        puthToTurbolinks(history_url);
      }

    }

    // table props
    const default_props = super.tabulatorDefaultProps
    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }


}