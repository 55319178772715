import { ApplicationController } from "../application_controller";
import BSN from "bootstrap.native"
import Rails from "@rails/ujs"
import Tabulator from "tabulator-tables";
import AutoNumeric from 'autonumeric';
import nerdamer from "nerdamer/nerdamer.core"
import { Draggable } from 'agnostic-draggable';

export default class extends ApplicationController {
  static targets = ["price_field", "price_by_group_field", "show_all_seasons",
    "modal_currency_field", "seasons_fields", "checkbox_price_by_group", "tourist_amount",
    "room_seasons_field", "seasons_grp_fields", "seasons_fit_title", "table",
    "fit_destroy", "grp_destroy", "group_destroy", "room_modal_xl", "room_modal_md", "room_modal_md_second",
    "main_form", "extra_price_field", "groups_fields", "is_price_by_season",
    "season_hidden_row"]

  connect() {
    // Main table
    if (this.hasTableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);
    }

    if (this.hasRoom_modal_xlTarget) {
      window.room_modal_xl = new BSN.Modal(this.room_modal_xlTarget, {
        backdrop: "static",
        keyboard: true
      });
    }

    if (this.hasRoom_modal_mdTarget) {
      window.room_modal_md = new BSN.Modal(this.room_modal_mdTarget, {
        backdrop: "static",
        keyboard: true
      });
    }

    if (this.hasRoom_modal_md_secondTarget) {
      window.room_modal_md_second = new BSN.Modal(this.room_modal_md_secondTarget, {
        backdrop: "static",
        keyboard: true
      });
    }

    // Main Form
    if (this.hasMain_formTarget) {
      this.addReloadFormTrigger(this.main_formTarget)
    }
  }

  tableRelod(event) {
    const table_target = document.getElementById("rooms_table")
    const supplier_tab_services = document.getElementById("supplier_tab_services")
    const content_url = supplier_tab_services.getAttribute("data-suppliers--rooms-table-load-url");
    // const content_url = this.data.get("table-load-url");
    table_target.tabulator.setData(content_url);
  }

  onShowAllSeasons(event){
    if (event.target.checked) {
      this.season_hidden_rowTargets.forEach(el => {
        el.classList.remove("hidden")
      })
    } else {
      this.season_hidden_rowTargets.forEach(el => {
        el.classList.add("hidden")
      })
    }
  }


  toggleSeasons(event) {
    let tr = event.currentTarget.closest('.tabulator-row')
    let icon = event.currentTarget.querySelector('i')
    if (tr) tr.classList.toggle("show-child-table")
    if (icon) {
      if (icon.classList.contains("fa-angle-down")) {
        icon.classList.remove("fa-angle-down")
        icon.classList.add("fa-angle-up")
      } else {
        icon.classList.remove("fa-angle-up")
        icon.classList.add("fa-angle-down")
      }
    }
  }

  // Create main table
  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.tableTarget;
    const default_props = super.tabulatorDefaultProps
    const height = document.getElementById('content').clientHeight;
    const table_id = this.data.get("table-id");
    const locale = super.appLocale;

    let addSeasons = this.addRoomSeasonsTable
    let getSeasonsColumns = this.seasonsColumns

    let table_props = {
      virtualDomHoz: false,
      virtualDomBuffer: 3000,
      index: "id",
      height: (height - 200) + "px",
      persistenceID: table_id,
      movableColumns: true,
      // columns
      columns: JSON.parse(columns),
      // sort
      headerSort: false,
      // initialSort: [
      //   { column: sort_column, dir: sort_dir }
      // ],

      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",

      rowFormatter: function (row) {
        const data = row.getData()
        let fit_table_title = data.season_title, grp_table_title = ""
        let fit_table_columns, grp_table_columns

        if (data.is_price_by_season.toString() == "true" || data.is_price_by_group.toString() == "true") {
          // console.log("1231231230------------------")
          if (data.is_price_by_group.toString() == "true") {
            fit_table_title = "<strong class='font-primary'>FIT (1 - " + (parseInt(data.grp_from) - 1) + ")</strong>"
            grp_table_title = "<strong class='font-primary'>GRP (" + data.grp_from + " - ...)</strong>"
            grp_table_columns = getSeasonsColumns(grp_table_title, data.vat_percent, data.tourist_tax_percent)
            grp_table_columns = grp_table_columns[locale]
          }
          fit_table_columns = getSeasonsColumns(fit_table_title, data.vat_percent, data.tourist_tax_percent)
          fit_table_columns = fit_table_columns[locale]

          addSeasons(row, fit_table_columns, data.fit_seasons)

          if (data.is_price_by_group.toString() == "true")
            addSeasons(row, grp_table_columns, data.grp_seasons)
        }
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }

  addRoomSeasonsTable(row, columns, table_data) {
    const data = row.getData()
    //create and style holder elements
    let holderEl = document.createElement("div");
    holderEl.classList = "child-table rooms-seasons-table";
    holderEl.style.padding = "0 0 10px 20px";
    let tableEl = document.createElement("div");
    holderEl.appendChild(tableEl);

    row.getElement().appendChild(holderEl);

    if (table_data) {
      tableEl.tabulator = new Tabulator(tableEl, {
        layout: "fitDataStretch",
        data: table_data,
        headerSort: false,
        columns: columns,
        rowFormatter: function (child_row) {
          const child_data = child_row.getData();
          if (!child_data.is_published) {
            child_row.getElement().classList.add("row--danger-bg")
          }
        }

      })
    }
  }



  seasonsColumns(title, vat_value, tourist_tax_value) {
    const columns = {
      "ru": [{
        title: "",
        field: "index",
        width: 40,
      },
      {
        title: title,
        field: "name",
        formatter: "html"
      },
      {
        title: "Цена без НДС",
        field: "price"
      },
      {
        title: "НДС - " + vat_value + "%",
        field: "vat"
      },
      {
        title: "Цена Нетто",
        field: "price_with_vat"
      },
      {
        title: "Тур сбор - " + tourist_tax_value + "%",
        field: "tourist_tax_value"
      },
      {
        title: "Доп. сумма",
        field: "extra_sum"
      },
      {
        title: "Цена",
        field: "total"
      },
      {
        title: "Доступен?",
        field: "is_published_text"
      }],
      "en": [{
        title: "",
        field: "index",
        width: 40,
      },
      {
        title: title,
        field: "name",
        formatter: "html"
      },
      {
        title: "Цена без НДС",
        field: "price"
      },
      {
        title: "НДС - " + vat_value + "%",
        field: "vat"
      },
      {
        title: "Цена Нетто",
        field: "price_with_vat"
      },
      {
        title: "Тур сбор - " + tourist_tax_value + "%",
        field: "tourist_tax_value"
      },
      {
        title: "Доп. сумма",
        field: "extra_sum"
      },
      {
        title: "Цена",
        field: "total"
      },
      {
        title: "Доступен?",
        field: "is_published_text"
      }]
    }
    return columns
  }

  // actions
  // priceFocusout(event) {
  //   let code = (event.keyCode ? event.keyCode : event.which);
  //   if (code == 9) {
  //     event.preventDefault();
  //     return;
  //   }
  // }

  priceChange(event) {
    const tr = event.currentTarget.closest("tr");
    const price_input = tr.querySelector("input.price_input")
    let vat_value = 0, price_with_vat_value = 0, total_value = 0;
    if (!price_input)
      return;
    if (price_input.value) {
      let price = parseFloat(price_input.value.replace(",", '.').replace(/[^\d.-]/g, ''))
      vat_value = this.getVatValueFromPrice(price)
      price_with_vat_value = this.getPriceWithVatValueFromPrice(tr, price)
    }
    // change vat
    this.setVatHtml(tr, vat_value)
    // change price_with_vat_value
    this.setPriceWithVatValue(tr, price_with_vat_value)
    // change total sum
    total_value = this.getTotalValue(tr);
    this.setTotalHtml(tr, total_value)
  }

  isVatChange(event) {
    const tr = event.currentTarget.closest("tr");
    const price_input = tr.querySelector("input.price_input")
    let vat_value = 0, price_with_vat_value = 0, total_value = 0;
    if (!price_input)
      return;
    if (price_input.value) {
      let price = parseFloat(price_input.value.replace(",", '.').replace(/[^\d.-]/g, ''))
      vat_value = this.getVatValueFromPrice(price)
      price_with_vat_value = this.getPriceWithVatValueFromPrice(tr, price)
    }
    // change vat
    this.setVatHtml(tr, vat_value)
    // change price_with_vat_value
    this.setPriceWithVatValue(tr, price_with_vat_value)
    // change total sum
    total_value = this.getTotalValue(tr);
    this.setTotalHtml(tr, total_value)

  }

  priceWithVatChange(event) {
    const tr = event.currentTarget.closest("tr");
    const price_with_vat_input = tr.querySelector("input.price_with_vat_input")
    let vat_value = 0, price_value = 0, total_value = 0;
    if (!price_with_vat_input)
      return;
    if (price_with_vat_input.value) {
      let price_with_vat = parseFloat(price_with_vat_input.value.replace(",", '.').replace(/[^\d.-]/g, ''))
      vat_value = this.getVatValueFromPriceWithVat(price_with_vat)
      price_value = this.getPriceValueFromPriceWithVat(tr, price_with_vat)
    }
    // change vat
    this.setVatHtml(tr, vat_value)
    // change price_with_vat_value
    this.setPriceValue(tr, price_value)
    const price_input = tr.querySelector("input.price_input")
    // change total sum
    total_value = this.getTotalValue(tr);
    this.setTotalHtml(tr, total_value)
  }

  isTouristAmountChange(event) {
    this.recalculate_table()
  }

  isTouristTaxChange(event) {
    const tr = event.currentTarget.closest("tr");
    let total_value = 0;
    // change total sum
    total_value = this.getTotalValue(tr);
    this.setTotalHtml(tr, total_value)
  }

  isExtraSumChange(event) {
    const tr = event.currentTarget.closest("tr");
    let total_value = 0;
    // change total sum
    total_value = this.getTotalValue(tr);
    this.setTotalHtml(tr, total_value)
  }

  extraSumFormulaChange(event) {
    const tr = event.currentTarget.closest("tr");
    const extra_sum_input = tr.querySelector("input.extra_sum_input")
    let formula_value = 0, total_value = 0;
    if (!extra_sum_input)
      return;
    if (extra_sum_input.value) {
      formula_value = this.getExtraSumFormula(tr);
    }
    // change total sum
    total_value = this.getTotalValue(tr);
    this.setTotalHtml(tr, total_value)
  }

  // TOTAL
  // set
  setTotalHtml(tr, total_value) {
    const html = tr.querySelector(".room_season_total")
    if (!html)
      return;
    html.innerHTML = this.setFormat(total_value);
  }
  // get
  getTotalValue(tr) {
    let price_with_vat = this.getPriceWithVatValue(tr)
    let tourist_tax = this.getTouristTaxValue();
    let extra_sum = this.getExtraSumFormula(tr);
    const is_tourist_tax_checkbox = tr.querySelector("input.is_tourist_tax_checkbox")
    const is_tourist_tax = is_tourist_tax_checkbox.checked
    const is_extra_sum_checkbox = tr.querySelector("input.is_extra_sum_checkbox")
    const is_extra_sum = is_extra_sum_checkbox.checked

    if (!is_tourist_tax) tourist_tax = 0;
    if (!is_extra_sum) extra_sum = 0;
    return price_with_vat + tourist_tax + extra_sum
  }


  // // FORMULA
  // get
  getExtraSumFormula(tr) {
    const extra_sum_input = tr.querySelector("input.extra_sum_input")
    let formula_value = 0;
    try {
      let e = nerdamer(extra_sum_input.value);
      formula_value = e.evaluate();
    } catch (e) {
      extra_sum_input.setCustomValidity("");
      // expected output: "Parameter is not a number!"
    }
    return formula_value;
  }
  // set
  setPriceValue(tr, price_value) {
    const price_input = tr.querySelector("input.price_input")
    if (!price_input)
      return;
    price_input.value = this.setFormat(price_value);
  }


  // //       tourist_tax        // //
  // get
  getTouristTaxValue() {
    const tax_values = JSON.parse(this.data.get("bvr-currency-rates"))
    let current_currency = document.querySelector('input[name="room[price_currency]"]:checked').value;
    let tourist_amount = this.tourist_amountTarget.value || 0;
    const value = tourist_amount * parseFloat(tax_values[current_currency].toString())
    return value;
  }

  // set
  setTouristTaxHtml(tr) {
    const value = this.getTouristTaxValue();
    const html = tr.querySelector(".tourist_tax_value")
    if (!html)
      return;
    html.innerHTML = this.setFormat(value);
  }

  // //       VAT        // //
  // get
  getVatValueFromPrice(price_value) {
    const vat_percent = parseFloat(this.data.get("vat-percent"))
    const vat_value = price_value * vat_percent / 100;
    return vat_value;
  }
  // get
  getVatValueFromPriceWithVat(price_with_value) {
    const vat_percent = parseFloat(this.data.get("vat-percent"))
    const vat_value = price_with_value * vat_percent / (100 + vat_percent);
    return vat_value;
  }
  // set
  setVatHtml(tr, vat_value) {
    const vat_html = tr.querySelector(".vat_value")
    if (!vat_html)
      return;
    vat_html.innerHTML = this.setFormat(vat_value);
  }
  // // // // // // // // // // //

  // // Price
  // get
  getPriceValueFromPriceWithVat(tr, price_with_vat) {
    const vat_value = this.getVatValueFromPriceWithVat(price_with_vat)
    const is_vat_checkbox = tr.querySelector("input.is_vat_checkbox")
    const is_vat = is_vat_checkbox.checked
    // price_with_vat_value
    let price_value = 0
    if (is_vat) price_value = price_with_vat - vat_value;
    else price_value = price_with_vat;
    return price_value;
  }
  // set
  setPriceValue(tr, price_value) {
    const price_input = tr.querySelector("input.price_input")
    if (!price_input)
      return;
    // price_input.value = this.setFormat(price_value);
    price_input.numeric.set(price_value);
  }

  // //          PriceWithVat           //  //
  // get value
  getPriceWithVatValue(tr) {
    const price_with_vat_input = tr.querySelector("input.price_with_vat_input")
    let price_with_vat_value = 0
    if (!price_with_vat_input)
      return;
    price_with_vat_value = price_with_vat_input.numeric.getNumber();
    return price_with_vat_value;
  }

  getPriceWithVatValueFromPrice(tr, price_value) {
    const vat_value = this.getVatValueFromPrice(price_value)
    const is_vat_checkbox = tr.querySelector("input.is_vat_checkbox")
    const is_vat = is_vat_checkbox.checked
    // price_with_vat_value
    let price_with_vat_value = 0
    if (is_vat) price_with_vat_value = price_value + vat_value;
    else price_with_vat_value = price_value;
    return price_with_vat_value;
  }
  // set html
  setPriceWithVatValue(tr, price_with_vat_value) {
    const price_with_vat_input = tr.querySelector("input.price_with_vat_input")
    if (!price_with_vat_input)
      return;
    // price_with_vat_input.value = this.setFormat(price_with_vat_value);
    price_with_vat_input.numeric.set(price_with_vat_value);
  }

  setFormat(value) {
    if (!value)
      return 0;
    return AutoNumeric.format(value, {
      allowDecimalPadding: "floats",
      decimalPlaces: 2,
      digitGroupSeparator: " ",
      decimalCharacter: ".",
      decimalCharacterAlternative: ",",
      styleRules: {
        positive: "autoNumeric-positive"
      },
      minimumValue: 0
    });
  }

  updateCurrencyFields(event) {
    const currency = event.currentTarget.getAttribute("value");
    this.modal_currency_fieldTargets.forEach(el => {
      el.innerHTML = currency;
    });

    this.recalculate_table();

  }

  recalculate_table(){

    const fit_tbody = document.getElementById('fit_rooms_tbody');
    const grp_tbody = document.getElementById('grp_rooms_tbody');

    for (let i = 0; i < fit_tbody.rows.length; i++) {
      if (fit_tbody.rows[i]) {
        let tr = fit_tbody.rows[i];
        this.setTouristTaxHtml(tr)
        const total_value = this.getTotalValue(tr)
        this.setTotalHtml(tr, total_value)
      }
    }

    for (let i = 0; i < grp_tbody.rows.length; i++) {
      if (grp_tbody.rows[i]) {
        let tr = grp_tbody.rows[i];
        this.setTouristTaxHtml(tr)
        const total_value = this.getTotalValue(tr)
        this.setTotalHtml(tr, total_value)
      }
    }

  }

  setPriceBySeason(event) {
    this.disableEnableSeasonGroupForms()
  }

  // нажатие на включение разделение по группам
  setPriceByGroup(event) {
    this.disableEnableSeasonGroupForms()
  }

  // логика переключения форм сезонов
  disableEnableSeasonGroupForms() {
    const is_price_by_group = this.checkbox_price_by_groupTarget.checked
    const is_price_by_season = this.is_price_by_seasonTarget.checked
    // если цены по сезонам && цены по группам. показываем форму с сезонами+группами
    if (is_price_by_group && is_price_by_season) {
      this.showAllSeasonsButton(true)
      // скрываем форму групп
      this.showGroupForm(false)
      // показываем форму сезонов
      this.showSeasonsForm(true)
      // отключаем поле цены
      this.disableEnablePriceField(true)
      // отключаем поле екстра цены
      this.disableEnableExtraPriceField(is_price_by_season)
      // включаем поле GRP От
      this.disableEnablePriceByGroup(false)
      // показывам таблицу с полями GRP
      this.showSeasonsGroupForm(true)
      // is_destroy = false
      this.setDestroyEnableDisableRommSeason(false, "grp")
      this.setDestroyEnableDisableRommSeason(false, "fit")
      this.setDestroyEnableDisableRommSeason(true, "group")
    }
    // если цены не по сезонам && цены по группам. показываем форму с группами
    else if (is_price_by_group && !is_price_by_season) {
      this.showAllSeasonsButton(false)
      //скраваем форму сезонов
      this.showSeasonsForm(false)
      //показываем форму групп
      this.showGroupForm(true)
      // отключаем поле цены
      this.disableEnablePriceField(true)
      // отключаем поле екстра цены
      this.disableEnableExtraPriceField(true)
      // включаем поле GRP От
      this.disableEnablePriceByGroup(false)
      // показывам таблицу с полями GRP
      // this.showSeasonsGroupForm(is_price_by_group)
      // is_destroy = true
      this.setDestroyEnableDisableRommSeason(true, "grp")
      this.setDestroyEnableDisableRommSeason(true, "fit")
      this.setDestroyEnableDisableRommSeason(false, "group")
    }
    // если цены по сезонам && цены не по группам. показываем форму только сезонов без групп
    else if (!is_price_by_group && is_price_by_season) {
      this.showAllSeasonsButton(true)
      // скрываем форму групп
      this.showGroupForm(false)
      // показываем форму сезонов
      this.showSeasonsForm(true)
      // отключаем поле цены
      this.disableEnablePriceField(true)
      // отключаем поле екстра цены
      this.disableEnableExtraPriceField(true)
      // отключаем поле GRP От
      this.disableEnablePriceByGroup(true)
      // скрываем таблицу с полями GRP
      this.showSeasonsGroupForm(false)
      // is_destroy = true
      this.setDestroyEnableDisableRommSeason(true, "grp")
      this.setDestroyEnableDisableRommSeason(true, "group")
    }
    // если цены не по сезонам && цены не по группам. скрываем все формы
    else if (!is_price_by_group && !is_price_by_season) {
      this.showAllSeasonsButton(false)
      // скрываем форму групп
      this.showGroupForm(false)
      // показываем форму сезонов
      this.showSeasonsForm(false)
      // отключаем поле цены
      this.disableEnablePriceField(false)
      // отключаем поле екстра цены
      this.disableEnableExtraPriceField(false)
      // отключаем поле GRP От
      this.disableEnablePriceByGroup(true)
      // скрываем таблицу с полями GRP
      this.showSeasonsGroupForm(false)
      // is_destroy = true
      this.setDestroyEnableDisableRommSeason(true, "grp")
      this.setDestroyEnableDisableRommSeason(true, "fit")
      this.setDestroyEnableDisableRommSeason(true, "group")
    }
  }

  // поля от до
  disableEnablePriceByGroup(is_disabled) {
    const price_by_groupField = this.price_by_group_fieldTarget;
    price_by_groupField.disabled = is_disabled;
  }

  // _destroy = true
  setDestroyEnableDisableRommSeason(is_destroy, group_type) {
    let allFields
    if (group_type == "grp") allFields = this.grp_destroyTargets;
    else if (group_type == "group") allFields = this.group_destroyTargets;
    else allFields = this.fit_destroyTargets;

    if (allFields) {
      allFields.forEach((field) => {
        field.value = is_destroy;
      });
    }
  }

  // показывам таблицу с полями GRP
  showSeasonsGroupForm(is_show) {
    if (is_show) {
      this.seasons_grp_fieldsTarget.classList.remove("hidden");
      this.seasons_fit_titleTarget.classList.remove("hidden");
    } else {
      this.seasons_grp_fieldsTarget.classList.add("hidden");
      this.seasons_fit_titleTarget.classList.add("hidden");
    }
  }

  showAllSeasonsButton(is_show) {
    if (is_show) this.show_all_seasonsTarget.classList.remove("hidden");
    else this.show_all_seasonsTarget.classList.add("hidden");
  }

  // поле цена
  disableEnablePriceField(is_disabled) {
    this.price_fieldTarget.disabled = is_disabled;
  }

  disableEnableExtraPriceField(is_disabled) {
    this.extra_price_fieldTarget.disabled = is_disabled;
  }

  // disableEnablePriceField(is_disabled) {
  //   this.price_fieldTarget.disabled = is_disabled;
  // }

  // показывам таблицу с полями для сезона
  showSeasonsForm(is_show) {
    if (is_show) this.seasons_fieldsTarget.classList.remove("hidden");
    else this.seasons_fieldsTarget.classList.add("hidden");
  }

  showGroupForm(is_show) {
    if (is_show) this.groups_fieldsTarget.classList.remove("hidden");
    else this.groups_fieldsTarget.classList.add("hidden");
  }

  //
  // Forms
  //
  openModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    if (window.room_modal_xl) {
      this.setModalContent(window.room_modal_xl, url);
      super.draggable_modal('#room_modal_xl .modal-content');
    }
  }

  // create md modals(season, catalog value)
  createSeasonModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    // let reloadRoomFormVar = this.reloadRoomMainForm()
    if (window.room_modal_md) {
      this.removeBackdrops();
      this.setModalContent(window.room_modal_md, url)
      super.draggable_modal('#room_modal_md .modal-content');
      this.hideRoomModal();
      this.hideRoomTypeModal();
      // reset modal connect, remove leave page from form
    }
  }

  addReloadFormTrigger(form_element) {
    let controller;
    if (form_element) controller = this.formController(form_element)
    if (controller) {
      const is_reload_input = document.querySelector("#rooms_form_is_reload")
      if (is_reload_input) {
        is_reload_input.value = false;
        is_reload_input.addEventListener(
          "change",
          function (event) {
            if (is_reload_input.getAttribute('data-season-id')) controller.reloadRoomMainForm();
          }, false,
        );
      }

    }
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "suppliers--rooms")
  }


  reloadRoomMainForm() {
    let main_form = document.querySelector("#supplier_room_form")
    const is_reload_input = document.querySelector("#rooms_form_is_reload")
    if (main_form && is_reload_input) {
      const season_id = is_reload_input.getAttribute('data-season-id')
      const season_name = is_reload_input.getAttribute('data-season-name')

      const fit_tr_template = document.querySelector("#fit_rooms_tr_template")
      if (fit_tr_template && season_id && season_name) {
        let fit_rooms_tbody = document.querySelector("#fit_rooms_tbody")
        let new_tr = fit_tr_template.innerHTML
        const index = new Date().getTime() + 1;
        new_tr = new_tr.replace(/NEW_RECORD_SESSON_NAME/g, season_name)
        new_tr = new_tr.replace(/NEW_RECORD_SEASON_ID/g, season_id)
        // new_tr = new_tr.replace(/NEW_RECORD_INDEX/g, fit_rooms_tbody.rows.length + 1)
        new_tr = new_tr.replace(/NEW_RECORD/g, index)
        fit_rooms_tbody.insertAdjacentHTML('beforeend', new_tr)
      }

      const grp_tr_template = document.querySelector("#grp_rooms_tr_template")
      if (grp_tr_template && season_id && season_name) {
        const index = new Date().getTime() + 39;
        let grp_rooms_tbody = document.querySelector("#grp_rooms_tbody")
        let new_tr = grp_tr_template.innerHTML
        new_tr = new_tr.replace(/NEW_RECORD_SESSON_NAME/g, season_name)
        new_tr = new_tr.replace(/NEW_RECORD_SEASON_ID/g, season_id)
        // new_tr = new_tr.replace(/NEW_RECORD_INDEX/g, grp_rooms_tbody.rows.length + 1)
        new_tr = new_tr.replace(/NEW_RECORD/g, index)
        grp_rooms_tbody.insertAdjacentHTML('beforeend', new_tr)
      }
    }
  }

  // create md modals(season, catalog value)
  createRoomTypeModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    if (window.room_modal_md_second) {
      this.setModalContent(window.room_modal_md_second, url);
      super.draggable_modal('#room_modal_md_second .modal-content');
      this.hideRoomModal();
      this.hideSeasonModal();
    }
  }

  async setModalContent(modal, url) {
    // await - https://learn.javascript.ru/async-await#await
    let content_response = await fetch(url);
    let content = await content_response.text();
    //
    // https://thednp.github.io/bootstrap.native/#componentModal
    //
    if (modal) {
      this.removeBackdrops();
      modal.setContent(content);
      modal.show();
      modal.update();
    }

  }

  onRoomChange(event) {
    let room_name = event.target.choices.getValue().label;
    let tourist_amount = 1;
    if (/([Dd]ouble|[Tt]win)/i.test(room_name)) {
      tourist_amount = 2;
    } else if (/[Tt]riple/i.test(room_name)) {
      tourist_amount = 3;
    } else if (/[Qq]uadriple/i.test(room_name)) {
      tourist_amount = 4;
    }

    this.tourist_amountTarget.value = tourist_amount;
    this.recalculate_table()
  }

  removeBackdrops() {
    const modalsBackdrops = document.getElementsByClassName('modal-backdrop');

    // remove every modal backdrop
    for (let i = 0; i < modalsBackdrops.length; i++) {
      document.body.removeChild(modalsBackdrops[i]);
    }
  }

  hideRoomModal() {
    if (window.room_modal_xl) {
      window.room_modal_xl.hide();
      // window.room_modal_xl.update();
    }
  }

  hideSeasonModal() {
    if (window.room_modal_md) {
      window.room_modal_md.hide();
      window.room_modal_md.setContent("");
      window.room_modal_md.update();
    }
  }

  hideRoomTypeModal() {
    if (window.room_modal_md_second) {
      window.room_modal_md_second.hide();
      window.room_modal_md_second.setContent("");
      window.room_modal_md_second.update();
    }
  }

  showRoomModal() {
    if (window.room_modal_xl) {
      this.hideSeasonModal()
      this.hideRoomTypeModal()
      this.removeBackdrops();
      window.room_modal_xl.toggle();
      window.room_modal_xl.update();
    }
  }

  removeRoomModal() {
    if (window.room_modal_xl) {
      window.room_modal_xl.hide();
      window.room_modal_xl.setContent("");
      this.removeBackdrops();
    }
  }

}