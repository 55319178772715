// require("@rails/ujs").start()
require("bootstrap.native");
require("turbolinks").start()
// require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")
require("channels")
const moment = require('moment')
moment.locale('ru')
window.moment = moment

import '../stylesheets/application';
import "controllers";
const images = require.context("../images", true)

const importAll = (r) => r.keys().map(r)
importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));