import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["services_tab", "modal_currency_field", "price_field", "price_with_vat_field", "is_vat_field"]


  connect() {
    if (this.hasServices_tabTarget) {
      this.loadServicesTab();
    }
  }

  // actions

  updateCurrencyFields(event) {
    const currency = event.currentTarget.getAttribute("value");

    this.modal_currency_fieldTargets.forEach(el => {
      el.innerHTML = currency;
    })
  }

  priceChange(event) {
    const price_input = this.price_fieldTarget
    const is_vat_checkbox = this.is_vat_fieldTarget
    const is_vat = is_vat_checkbox.checked
    const price_with_vat_input = this.price_with_vat_fieldTarget
    let vat_value = 0, price_with_vat_value = 0, total_value = 0;


    if (!price_input)
      return;
    if (price_input.value) {
      let price = parseFloat(price_input.value.replace(",", '.').replace(/[^\d.-]/g, ''))
      vat_value = this.getVatValueFromPrice(price)
      price_with_vat_value = price
      if (is_vat) price_with_vat_value = price + vat_value;
    }
    price_with_vat_input.numeric.set(price_with_vat_value);
  }

  isVatChange(event) {
    const price_input = this.price_fieldTarget
    const is_vat_checkbox = this.is_vat_fieldTarget
    const is_vat = is_vat_checkbox.checked
    const price_with_vat_input = this.price_with_vat_fieldTarget
    let vat_value = 0, price_with_vat_value = 0, total_value = 0;


    if (!price_input)
      return;
    if (price_input.value) {
      let price = parseFloat(price_input.value.replace(",", '.').replace(/[^\d.-]/g, ''))
      vat_value = this.getVatValueFromPrice(price)
      price_with_vat_value = price
      if (is_vat) price_with_vat_value = price + vat_value;
    }
    price_with_vat_input.numeric.set(price_with_vat_value);
  }

  priceWithVatChange(event) {
    const price_input = this.price_fieldTarget
    const is_vat_checkbox = this.is_vat_fieldTarget
    const is_vat = is_vat_checkbox.checked
    const price_with_vat_input = this.price_with_vat_fieldTarget
    let vat_value = 0, price = 0;


    if (!price_with_vat_input)
      return;
    if (price_with_vat_input.value) {
      let price_with_vat = parseFloat(price_with_vat_input.value.replace(",", '.').replace(/[^\d.-]/g, ''))
      vat_value = this.getVatValueFromPriceWithVat(price_with_vat)
      price = price_with_vat
      if (is_vat) price = price_with_vat - vat_value;
    }
    price_input.numeric.set(price);
  }

  // //       VAT        // //
  // get
  getVatValueFromPrice(price_value) {
    const vat_percent = parseFloat(this.data.get("vat-percent"))
    const vat_value = price_value * vat_percent / 100;
    return vat_value;
  }
  // get
  getVatValueFromPriceWithVat(price_with_value) {
    const vat_percent = parseFloat(this.data.get("vat-percent"))
    const vat_value = price_with_value * vat_percent / (100 + vat_percent);
    return vat_value;
  }


  //
  // Forms
  //

  openModalComplex(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  openModalSimple(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }




  async loadServicesTab() {
    const url = this.data.get("tab-content-url");
    const tab_target = this.services_tabTarget;

    fetch(url, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/html',
        'Accept': 'application/html'
      },
      credentials: 'same-origin'
    }).then((response) => {
      // if (response.status == 200) {
      response.text().then((content) => {
        tab_target.innerHTML = content;
      })
      // }
    });
  }


}