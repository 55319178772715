import { ApplicationController } from "../application_controller";
import Tabulator from "tabulator-tables";
export default class extends ApplicationController {
    static targets = ["table", "search"]

    connect() {
        // Main table
        if (this.hasTableTarget) {
            const content_url = this.data.get("table-load-url");
            this.createTable(content_url);
        }
    }

    filterTable() {
        this.setFilterToTable();
    }


    setFilterToTable() {
        let filters = []

        if (this.hasSearchTarget) {
            filters.push({
                field: "q",
                type: "=",
                value: this.searchTarget.value
            })
        }

        if (filters.length) {
            this.tableTarget.tabulator.setFilter(filters);
        }
    }

    // Create main table
    async createTable(url) {
        const columns = this.data.get("table-columns");
        const table_target = this.tableTarget;
        const default_props = super.tabulatorDefaultProps
        const height = document.getElementById('content').clientHeight;
        const table_id = this.data.get("table-id");
        // const locale = super.appLocale;

        // let addSeasons = this.addRoomSeasonsTable
        // let getSeasonsColumns = this.seasonsColumns
        // console.log(columns)
        let table_props = {
            virtualDomHoz: false,
            virtualDomBuffer: 3000,
            index: "id",
            height: (height - 150) + "px",
            persistenceID: table_id,
            movableColumns: false,
            // columns
            columns: JSON.parse(columns),
            // sort
            headerSort: false,
            // pagination
            paginationDataSent: {
                "size": "per_page"
            },
            paginationSize: 25,
            // Ajax
            ajaxURL: url,
            ajaxSorting: true,
            ajaxLoader: true,
            ajaxFiltering: true,
            ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
            ajaxProgressiveLoad: "scroll",


            // rowFormatter: function (row) {
            //     const data = row.getData()
            //     let fit_table_title = data.season_title, grp_table_title = ""
            //     let fit_table_columns, grp_table_columns
            //
            //     if (data.is_price_by_season.toString() == "true" || data.is_price_by_group.toString() == "true") {
            //         console.log("1231231230------------------")
            //         if (data.is_price_by_group.toString() == "true") {
            //             fit_table_title = "<strong class='font-primary'>FIT (1 - " + (parseInt(data.grp_from) - 1) + ")</strong>"
            //             grp_table_title = "<strong class='font-primary'>GRP (" + data.grp_from + " - ...)</strong>"
            //             grp_table_columns = getSeasonsColumns(grp_table_title, data.vat_percent, data.tourist_tax_percent)
            //             grp_table_columns = grp_table_columns[locale]
            //         }
            //         fit_table_columns = getSeasonsColumns(fit_table_title, data.vat_percent, data.tourist_tax_percent)
            //         fit_table_columns = fit_table_columns[locale]
            //
            //         addSeasons(row, fit_table_columns, data.fit_seasons)
            //
            //         if (data.is_price_by_group.toString() == "true")
            //             addSeasons(row, grp_table_columns, data.grp_seasons)
            //     }
            // }
        }

        table_props = Object.assign(default_props, table_props);
        // create table
        table_target.tabulator = new Tabulator(table_target, table_props);
        table_target.tabulator.setLocale(super.appLocale);
    }
}