import BSN from "bootstrap.native"
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["form_content", "period_template"]

  openModalForm(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  addNewPeriod(event) {
    event.preventDefault()
    const daterange_field = this.period_templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.form_contentTarget.insertAdjacentHTML('beforeend', daterange_field)
  }

  removePeriod(event) {
    event.preventDefault()
    let form_group = event.target.closest(".form-group")
    if (form_group.dataset.newRecord == "true") {
      form_group.remove()
    } else {
      form_group.querySelector("input[name*='_destroy']").value = 1
      form_group.style.display = 'none'
    }
  }
}