import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["content", "fields_content", "field",
    "autoprolongation_input", "notification_input",
    "contractable_tab", "contractable_table"]

  //
  // Actions
  //
  newContact(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }

  editContact(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }
}
