import InfiniteScroll from "infinite-scroll"
// import Tabulator from 'tabulator-tables';
import { ApplicationController } from "../application_controller";

export default class extends ApplicationController {
  static targets = ["content", "fields_content", "field",
    "autoprolongation_input", "notification_input",
    "contractable_tab", "contractable_table", "contracts_tbody"]


  connect() {
    if (this.hasContractable_tabTarget) {
      this.loadContractableTab();
    }

    if (this.hasContracts_tbodyTarget) {
      const nextLink = this.element.querySelector('.contracts_pagination__next');
      if (nextLink) {
        // let updateSidebarStick = super.updateSidebarStick
        const infScroll = new InfiniteScroll(this.contracts_tbodyTarget, {
          // options
          path: '.contracts_pagination__next',
          append: 'tr.item',
          history: false,
          prefill: true
        });

        // infScroll.on('load', function () {
        //   updateSidebarStick()
        // });
      }
    }

  }

  showHideFields(event) {
    const id = event.currentTarget.getAttribute("id");
    if (this.hasFields_contentTarget) {
      if (id == "no_contract") this.hide();
      else this.show();
    }
  }

  // enable disable notification
  notification(event) {
    if (this.hasNotification_inputTarget) {
      const notification_field = this.notification_inputTarget;
      const checkbox = event.currentTarget;
      notification_field.disabled = !event.currentTarget.checked;
    }
  }

  // enable disable autoprolongation
  autoprolongation(event) {
    if (this.hasAutoprolongation_inputTarget) {
      const autoprolongation_field = this.autoprolongation_inputTarget;
      autoprolongation_field.disabled = !event.currentTarget.checked;
    }
  }

  show() {
    const contract_fields = this.fields_contentTarget;
    // disabled = false
    this.enableDisableFields(false)
    contract_fields.classList.remove("hidden");
  }

  hide() {
    const contract_fields = this.fields_contentTarget;
    contract_fields.classList.add("hidden");
    // disabled = true
    this.enableDisableFields(true)
  }

  //
  // is_disabled = true || false
  //
  enableDisableFields(is_disabled) {
    const allFields = this.fieldTargets;
    allFields.forEach((field) => {
      field.disabled = is_disabled;
    });
  }

  showContractModalForm(event) {
    event.preventDefault();
    let url = this.data.get("form-url");
    this.openLayoutModalMD(url);
  }


  async loadContractableTab() {
    const url = this.data.get("tab-content-url");
    const tab_target = this.contractable_tabTarget;

    fetch(url, {
      method: 'GET',
      headers: {
        'X-CSRF-Token': document.getElementsByName('csrf-token')[0].getAttribute('content'),
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/html',
        'Accept': 'application/html'
      },
      credentials: 'same-origin'
    }).then((response) => {
      // if (response.status == 200) {
      response.text().then((content) => {
        tab_target.innerHTML = content;
      })
      // }
    });
  }


}
